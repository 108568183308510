#sankey {
    width: 800px;
    height: 600px;
    border: 1px solid black;
}

svg .link {
  /*  opacity: 0.5; */
    /* fill: steelblue; */
}

svg .link path:hover {
    opacity: 1 !important;
}

svg g.sankey {
    font-size: 10pt;
}
svg .node line {
    stroke-width: 1px;
    stroke: transparent;
}

svg .node-type-process line {
    /* stroke-width: 4px; */
    /* stroke: #888; */
}

svg .group rect {
    fill: transparent;
    stroke: #bbb;
    /* stroke-width: 0.5px; */
}

/* svg .group text {
    fill: transparent;
} */

.background-dotted {
    --dot-bg: white;
    --dot-color: gray;
    --dot-size: 1px;
    --dot-space: 25px;
    background:
        linear-gradient(90deg, var(--dot-bg) calc(var(--dot-space) - var(--dot-size)), transparent 1%) center / var(--dot-space) var(--dot-space),
        linear-gradient(var(--dot-bg) calc(var(--dot-space) - var(--dot-size)), transparent 1%) center / var(--dot-space) var(--dot-space),
        var(--dot-color);
    border: none;
}

  .tooltip-background {
    rx: 5;
    fill: none;
  }

  .tooltip-text {
    font-size: 12px;
    font-family: Arial, sans-serif;
    width:250px;
    height: 100px;
    position: fixed;
    display: none;
  }

  .tooltip-text div.tooltip_text, .tooltip_topics {
    padding: 10px;
    background-color: #F1F1F1;
    border: 1px solid #CCCCCC;
    font-size: 14px;
    border-radius: 4px;
    box-shadow: 0px 0px 6px 2px #AAA;
    width:250px;
  }
  .tooltip_topics {
    display: none;
    position:fixed;
    width:auto;
    max-width:250px;
    z-index:99;
  }
  .node_description {
    cursor: pointer;
  }
  .title_count {
    font-weight: normal;
  }
  g.groups {
    margin-bottom: 20px;
  }