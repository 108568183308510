.DateContainer {
  /* display: flex;
  align-items: center;
  width: 50vw;
  height: 25px;
  border: 1px solid black;
  border-radius: 8px; */
  /* background-color: white; -->set this background color to whatever backgroundcolor you want the picker to be  */
  
  position: relative;
}

.dateInput {
  width: 100%;
  height: 100%;
}

.dateInput input {
  width: calc(100% + 25px);
  height: 25px;
  border: none;
  background: transparent;
  z-index: 1;
}

.icon {
  padding-top: 5px;
  position: absolute;
  right: -8px;
  width: 30px;
}

.icon svg {
  margin-top: 3px;
}

textarea:focus,
input:focus {
  outline: none;
}


.datePicker {
  width: 100%;
  padding: 0px 10px;

}

.react-datepicker-wrapper,
.react-datepicker__input-container,
.react-datepicker__input-container input {
  display: block;
  width: 100%;
  /* background-color: red; */
}


/* selected date box css */

.clover .react-datepicker__day--selected {
 background-color: #bcd7ae;
}


.vurbalize .react-datepicker__day--selected {
  background-color: #d2afc3;
}

.lightspeedhq .react-datepicker__day--selected {
  background-color: #ffbeaf;
}

.mytonomy .react-datepicker__day--selected {
  background-color: #bcd3e2;
}

.payactiv .react-datepicker__day--selected {
  background-color: rgb(204, 218, 252);
  }

.All .react-datepicker__day--selected {
  background-color: #6A1F70;
}


/* header css */
.react-datepicker__header {
background: white;
border: none;
font-weight: bold;
font-family: 'Poppins'
}



/* bottom button in dropdown css */
.react-datepicker__today-button {
display: none;
}


.react-datepicker__month-container {
background: white;
font-family: 'Poppins';
border: none;
}


/* .react-datepicker__day--keyboard-selected {
background-color: #BD88C1;
color: white;
} */


/* ########################## REACT-SELECT-OPTION-CUSTOM-WIDTH ############################### */
 .selectionOptionCustomWidth .css-1nmdiq5-menu {
  top: 100%;
  position: absolute;
  width: 100%;
  z-index: 1;
  background-color: hsl(0, 0%, 100%);
  border-radius: 4px;
  box-shadow: 0 0 0 1px hsla(0, 0%, 0%, 0.1), 0 4px 11px hsla(0, 0%, 0%, 0.1);
  margin-bottom: 8px;
  margin-top: 8px;
  box-sizing: border-box;

  
  @media screen and (min-width: 1200px) {
      width: 400px;
  }
}
/* 
.selectionOptionCustomWidth .css-1n6sfyn-MenuList {
  width: 100%;
  overflow-y: auto;
  position: relative;
  -webkit-overflow-scrolling: touch;
  padding-bottom: 4px;
  padding-top: 4px;
  box-sizing: border-box;
} */

/* .selectionOptionCustomWidth .css-1n6sfyn-MenuList div {
  cursor: default;
  display: block;
  font-size: inherit;
  width: 100%;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  background-color: transparent;
  color: #000000;
  padding: 8px 12px;
  box-sizing: border-box;
}  */