/* .Dropdown-control {
    background-color: rgb(59, 59, 59) !important;
    border: none !important;
}


.Dropdown-placeholder {
    color: white;
}

.Dropdown-arrow {
    background-color: white !important;
} */


.Dropdown-control{
    background-color: #3b3b3b !important;
    color: aliceblue !important;
    border: none !important;
}

.css-1u9des2-indicatorSeparator{
    display: none;
}


.css-1fdsijx-ValueContainer {
    color: aliceblue !important;
}

/* *************** Timezone selection custom styling *********************** */
.timezoneSelectCustomStyle .css-1dimb5e-singleValue{
    color: #ffffff;
}

/* *************** Timezone selection custom styling *********************** */

/* *************** User list checkTable css *********************** */
.userCheckTable{
    overflow-x: auto;
}
.userCheckTable::-webkit-scrollbar {
    -webkit-appearance: none;
}

.userCheckTable::-webkit-scrollbar:vertical {
    width: 11px;
}

.userCheckTable::-webkit-scrollbar:horizontal {
    height: 11px;
}

.userCheckTable::-webkit-scrollbar-thumb {
    border-radius: 8px;
    border: 2px solid white;
    /* should match background, can't be transparent */
    background-color: #ECECEC;
}
/* *************** User list checkTable css *********************** */