@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=DM+Sans:wght@400;500;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap');


@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
	html {
		font-family: 'DM Sans', sans-serif !important;
		font-feature-settings: 'kern' !important;
		-webkit-font-smoothing: antialiased;
		letter-spacing: -0.5px;
	}
}

input.defaultCheckbox::before {
	content: url(../src/assets/svg/checked.svg);
	color: white;
	opacity: 0;
	height: 16px;
	width: 16px;
	position: absolute;
	left: 50%;
	transform: translate(-50%, 0px);
}

input:checked.defaultCheckbox::before {
	opacity: 1;
}

 /****************************** Check table css ********************/
table.tablelist tr {
	margin-bottom: 10px; /* Adjust the value to set the desired space between rows */
  }


table.tablelist {
	padding-top: 0px;
	margin-top: 0px;
	border-collapse: separate;
	border-spacing: 0 5px;
  }
  
/* table.tablelist thead tr{
	border: 2px solid #FFFFFF;
}  */

table.tablelist th {
	padding-top: 0px;
	background-color: #FFFFFF;
  /* border-bottom: 1px solid #ececec; */
  /* border-top: 1px solid #ececec; */
}

table.tablelist td {
  border-bottom: 1px solid #ececec;
  border-top: 1px solid #ececec;
}
 /****************************** Check table css ********************/


/* ---------filters------------- */

.react-datepicker-wrapper svg {
	width: 32px;
	height: 30px;
	top: 50%;
	transform: translateY(-50%);
	right: 0px;
}

.react-datepicker__view-calendar-icon input {
	padding: 5px 32px 5px 7px !important;
}
.double_time_picker .react-datepicker__view-calendar-icon input {
	padding: 0px !important;
}
.filters .react-time-picker {
	padding-right:8px;
}
.filters .react-datepicker-wrapper {
	width: 9.5rem;
}

.filters .react-time-picker__inputGroup {
	min-width: 45px;
}

.advanced_filters .react-time-picker__inputGroup {
	min-width: max-content;
}

.filters .react-time-picker__inputGroup__hour {
	padding-right:8px;
}

.filters .react-time-picker__inputGroup__minute, .filters .react-time-picker__inputGroup__leadingZero {
	padding-left:8px;
}

/* ---------time picker------- */

.react-time-picker__wrapper {
	border: unset !important;
}

.react-time-picker__clear-button {
	display: none;
}

.advance_filter .react-datepicker-wrapper {
	width: 8rem;
}

/* ----------dropdown------ */
.filters .Dropdown-placeholder.is-selected {
	text-overflow: ellipsis;
	white-space: nowrap;
}

.filters .Dropdown-control {
	font-weight: 400;
	line-height: 30px;
	color: #3B3B3B;
	padding: 5px 41px 5px 7px;
	border: 1px solid rgba(59, 59, 59, 0.1);
	border-radius: 4px;
}

/* input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button,
input[type=number]::-webkit-inner-spin-button *,
input[type=number]::-webkit-outer-spin-button * {
	opacity: 1 !important;
	background-color: transparent;
} */

/* .numericFilter__input input[type=number]::-webkit-inner-spin-button,
	input[type=number]::-webkit-outer-spin-button,
	input[type=number]::-webkit-inner-spin-button *,
	input[type=number]::-webkit-outer-spin-button * {
	
		-webkit-appearance:initial;
		background-color: black;
		margin: 0;
} */


.filters input[type=checkbox]:not(.multiple_option) {
	-webkit-appearance: none;
	-moz-appearance: none;
	appearance: none;
	display: none;
}

.checkboxFilterOption.nestedFilterCheckbox::before {
	border: 2px solid #000;
}
.checkboxFilterOption.nestedFilterCheckbox::after {
	border-bottom: 2px solid #000;
    border-right: 2px solid #000;
}
.checkboxFilterOption::before {
	content: '';
	width: 14px;
	height: 14px;
	border-radius: 4px;
	border: 2px solid #fff;
	position: absolute;
	right: -22px;
	top: 50%;
	transform: translateY(-50%);
}
.checkboxFilter::after {
	content: '';
    width: 4px;
    height: 8px;
    border-bottom: 2px solid #fff;
    border-right: 2px solid #fff;
    position: absolute;
    right: -17px;
    top: 50%;
    transform: translateY(-50%) rotate(45deg);
}
.checkboxFilterOption_black::before {
	border: 2px solid #000;
	top: 12px;
}
.checkboxFilter_black::after {
    border-bottom: 2px solid #000;
    border-right: 2px solid #000;
	top: 12px;
}
.react-time-picker__clock { display: none; }

/******* CSS for Chat transcription page ************/
.selected_feed svg path { 
  fill: #9F336C;
}

.feedback_icon.selected_feed svg path {
  stroke: #FFFFFF;
}
.feedback_icon.selected_feed svg path:first-child {
	fill: #9F336C;
	stroke: #9F336C;
}

/************ Table css ****************/

.sorting.DESC:after {
	border-style: solid;
	border-width: 2px 2px 0 0;
	content: '';
	display: inline-block;
	height: 10px;
	margin-left: 10px;
	position: relative;
	top: 12px;
	vertical-align: top;
	width: 10px;
	transform: rotate(-45deg);
}

.sorting.ASC:after {
	border-style: solid;
	border-width: 2px 2px 0 0;
	content: '';
	display: inline-block;
	height: 10px;
	margin-left: 10px;
	position: relative;
	top: 8px;
	vertical-align: top;
	width: 10px;
	transform: rotate(135deg);
}

.loading-container {
	position: absolute;
    width: 100%;
    height: 100%;
    background: #FFFFFF;
    opacity: 0.7;
}

.loading-div {
	height:30px;
	width:30px;
	margin:60px auto;
	-webkit-animation: rotation .6s infinite linear;
	-moz-animation: rotation .6s infinite linear;
	-o-animation: rotation .6s infinite linear;
	animation: rotation .6s infinite linear;
	border-left:4px solid var(--primary-color);
	border-right:4px solid var(--primary-color);
	border-bottom:4px solid var(--primary-color);
	border-top:4px solid rgba(170, 170, 170, 0.53);
	border-radius:100%;
}

@-webkit-keyframes rotation {
	from {-webkit-transform: rotate(0deg);}
	to {-webkit-transform: rotate(359deg);}
 }
 @-moz-keyframes rotation {
	from {-moz-transform: rotate(0deg);}
	to {-moz-transform: rotate(359deg);}
 }
 @-o-keyframes rotation {
	from {-o-transform: rotate(0deg);}
	to {-o-transform: rotate(359deg);}
 }
 @keyframes rotation {
	from {transform: rotate(0deg);}
	to {transform: rotate(359deg);}
 }

 .m-background {
	background-color: var(--primary-color) !important;
 }
 
 .m-background-opacity {
	background-color: var(--primary-light-color) !important;
 }
 
 .m-background-hover:hover {
	background-color: var(--primary-color) !important;
	color: #FFFFFF;
 }

 .m-text {
	color: var(--primary-color) !important;
 }

 .m-svg-color svg path  {
	fill: var(--primary-color) !important;	
	stroke: none;	
 }

 /****************************** Bubble chart css ********************/
 .bubble-text tspan {
	font-size:90%;
 }

 /****************************** Area chart css ********************/

.toolbarPosition .apexcharts-toolbar{
	@media screen and (min-width: 605px) {
	    top: -68px !important;
	}
 }
 .apexcharts-zoomin-icon{
	margin: 0px 20px;
 }
 .apexcharts-menu-icon{
	margin: 0px 20px;
	/* transform: scale(1.7); */
 }

 .apexcharts-zoomin-icon,
 .apexcharts-menu-icon,
 .apexcharts-zoomout-icon {
 	transform: scale(1) !important;
 	border: 1px solid rgb(228, 245, 250);
 	padding: 2px;
    cursor: pointer;
	width: 28px !important;
	height: 28px !important;
	border-radius: 5px;
	line-height: 24px;
	color: #6E8192;
	text-align: center;
 }

 /****************************** Area chart css ********************/


.custom-dropdown {
	width: 150px !important;
}

 /****************************** Date filter search css ********************/
.customDatePickerColor .react-datepicker__day--selected{
	background-color: var(--primary-color) !important;
}

.user-msg:after {
 display: none;
}